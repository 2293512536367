<!--
 * @Description: 
 * @Version: 2.0
 * @Date: 2023-07-21 16:55:30
 * @LastEditors: Fiona.xu
 * @LastEditTime: 2023-07-24 08:22:53
-->
<!-- 我的发布 -->
<template>
  <div class="my-publish-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '我的发布',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-if="tableData.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
        class="list-box"
      >
        <van-cell
          v-for="(item, i) in tableData"
          :key="i"
          class="item"
          @click="handleEidt(item)"
        >
          <div class="item-box">
            <van-tag
              v-if="item.isTop == 1"
              plain
              class="unget"
              round
              type="primary"
              >置顶</van-tag
            >
            <p slot="title" class="title">
              {{ item.naTitle }}
            </p>
          </div>
          <div class="time">
            {{ item.onlineTime }} &nbsp;<van-icon name="arrow" size="14" />
          </div>
        </van-cell>
      </van-list>
      <van-empty v-else description="暂无数据"></van-empty>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getNoticeList } from "@/api/notice/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      tableData: [],
      total: 0,
      listQuery: {
        homePageType: null,
        pageNum: 1,
        pageSize: 10,
        dictCode: "",
        isOnline: null,
        isOwnerPublish: 1,
        publishBeginTime: "",
        publishEndTime: "",
        subStaffName: "",
        naTitle: "",
        isOnlyNotice: 0,
        naType: 1,
        sourceType: "APP_OA",
      },
    };
  },

  components: {TopWrapper},

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    handleEidt(item) {
      this.$router.push({
        path: "/publishDetail",
        query: {
          id: item.naCode,
        },
      });
    },
    getList() {
      const params = {
        ...this.listQuery,
      };
      getNoticeList(params).then((res) => {
        this.loading = false;
        this.refreshing = false;
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.tableData = this.tableData.concat(data.records);
            this.total = data.total;
            if (this.tableData.length >= data.total) {
              //如果没有数据了，把finished设置为true，之后就不会触发加载更多
              this.finished = true;
            } else {
              if (this.listQuery.pageNum >= data.pages) {
                //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                this.finished = true;
              } else {
                this.listQuery.pageNum++;
              }
            }
          }
        }
      });
    },
    onRefresh() {
      if (this.tableData.length < this.total) {
        this.listQuery.pageNum = 1;
        this.finished = false; //清空类表数据
        this.loading = true; //加载状态
        this.refreshing = false;
        this.getList();
      } else {
        this.refreshing = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my-publish-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  .list-box {
    margin: 0.3rem;
    padding: 0rem 0.3rem;
    background: #fff;
    .item {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      cursor: pointer;
      height: 1rem;
      line-height: 1rem;
      .item-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        flex: 1;
        .title {
          font-weight: 400;
          color: #333333;
          font-size: 0.3rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1;
        }
        .unget {
          margin-right: 0.1rem;
          width: 0.7rem;
          height: 0.34rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          background: rgba(0, 148, 238, 0.1);
          // padding: 0.01rem 0.12rem;
          text-align: center;
        }
        /deep/ .van-tag {
          font-size: 0.2rem;
        }
      }

      .time {
        width: 3rem;
        text-align: right;
        font-size: 0.22rem;
        color: #999999;
      }
    }
    ::v-deep .van-cell {
      padding: 0.3rem 0rem;
    }
    ::v-deep .van-cell::after {
      border-bottom: 1px solid #e5e5e5;
    }
    ::v-deep .van-cell__value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
